import React from "react"
import { MaxWidthProps } from "styled-system"
import * as S from "./elements"
import { IconType, ButtonVariant } from "./Button"

// TODO: Muting error, fix as soon as zeit/next.js/issues/7915 resolved
const originalError = console.error

console.error = (...args) => {
  if (/Warning.*Function components cannot be given refs/.test(args[0])) {
    return
  }
  originalError.call(console, ...args)
}

interface ButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    MaxWidthProps {
  variant?: ButtonVariant
  icon?: IconType
}

export const ButtonAnchor: React.FC<ButtonProps> = ({
  variant = "purple-border",
  icon,
  children,
  ...rest
}) => {
  const inner = (
    <>
      {icon === "add-to-bag" && <S.AddToBag />}
      {icon === "arrow-left" && <S.ArrowLeft />}
      {icon === "configure" && <S.Configure />}
      {children}
      {icon === "arrow-right" && <S.ArrowRight />}
    </>
  )
  if (variant === "white-border") {
    return (
      <S.WhiteBorderButtonAnchor icon={icon} {...rest}>
        {inner}
      </S.WhiteBorderButtonAnchor>
    )
  } else if (variant === "purple-border") {
    return (
      <S.PurpleBorderButtonAnchor icon={icon} {...rest}>
        {inner}
      </S.PurpleBorderButtonAnchor>
    )
  } else if (variant === "dark-border") {
    return (
      <S.DarkBorderButtonAnchor icon={icon} {...rest}>
        {inner}
      </S.DarkBorderButtonAnchor>
    )
  } else if (variant === "purple") {
    return (
      <S.PurpleButtonAnchor icon={icon} {...rest}>
        {inner}
      </S.PurpleButtonAnchor>
    )
  }
  return null
}
