import styled, { css } from "styled-components"
import { Variant, Style } from "./"
import { ParagraphSStyle } from "@components/Typography/TextStyles/ParagraphS"

export const Button = styled.button<{ variant?: Variant; styling?: Style }>`
  ${ParagraphSStyle};
  cursor: pointer;
  color: ${(p) => p.theme.colors.text.light};
  transition: color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  background: none;

  svg {
    fill: currentColor;
    width: 1rem;
    height: 1rem;
    transition: transform 0.2s ease-in-out;
    ${(p) =>
      p.variant === "arrow-left"
        ? css`
            margin-right: 0.5rem;
          `
        : css`
            margin-left: 0.5rem;
          `};
  }

  &:disabled {
    pointer-events: none;
    color: ${(p) => p.theme.colors.text.light};
    &:hover,
    &:focus,
    &:active {
      cursor: not-allowed;
    }
  }

  &:hover {
    color: ${(p) => p.theme.colors.text.dark};
    svg {
      ${(p) =>
        p.variant === "arrow-right"
          ? css`
              transform: translateX(0.25rem);
            `
          : css`
              transform: translateX(-0.25rem);
            `}
    }
  }

  ${(p) =>
    p.styling &&
    css`
      text-transform: uppercase;
      color: ${(p) => p.theme.colors.purple};
      letter-spacing: 2px;
    `}
`
