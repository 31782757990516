import React from "react"
import { MaxWidthProps } from "styled-system"
import * as S from "./elements"

export type ButtonVariant =
  | "white-border"
  | "purple-border"
  | "dark-border"
  | "purple"
export type IconType = "arrow-right" | "add-to-bag" | "arrow-left" | "configure"
export type ButtonState = "loading" | "success" | ""

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    MaxWidthProps {
  variant?: ButtonVariant
  icon?: IconType
  state?: ButtonState
}

export const Button: React.FC<ButtonProps> = ({
  variant = "purple-border",
  icon,
  state,
  disabled,
  children,
  ...rest
}) => {
  const loading = state === "loading"
  const inner = (
    <>
      {icon === "add-to-bag" && <S.AddToBag />}
      {icon === "arrow-left" && <S.ArrowLeft />}
      {icon === "configure" && <S.Configure />}
      {children}
      {icon === "arrow-right" && <S.ArrowRight />}
    </>
  )
  if (variant === "white-border") {
    return (
      <S.WhiteBorderButton icon={icon} disabled={loading || disabled} {...rest}>
        {loading ? <S.Loader /> : inner}
      </S.WhiteBorderButton>
    )
  } else if (variant === "purple-border") {
    return (
      <S.PurpleBorderButton
        icon={icon}
        disabled={loading || disabled}
        {...rest}
      >
        {loading ? <S.Loader /> : inner}
      </S.PurpleBorderButton>
    )
  } else if (variant === "dark-border") {
    return (
      <S.DarkBorderButton icon={icon} disabled={loading || disabled} {...rest}>
        {loading ? <S.Loader /> : inner}
      </S.DarkBorderButton>
    )
  } else if (variant === "purple") {
    return (
      <S.PurpleButton icon={icon} disabled={loading || disabled} {...rest}>
        {loading ? <S.Loader /> : inner}
      </S.PurpleButton>
    )
  }
  return null
}
