import React from "react"
import * as S from "./elements"
import { ArrowRight, ArrowLeft } from "@components/Icons"

export type Variant = "arrow-right" | "arrow-left"
export type Style = "purple-caps" | "regular"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  styling?: Style
}

export const ArrowButton: React.FC<Props> = ({
  children,
  variant = "arrow-right",
  styling = "regular",
  ...rest
}) => {
  return (
    <S.Button variant={variant} styling={styling} {...rest}>
      {variant === "arrow-left" && <ArrowLeft />}
      {children}
      {variant === "arrow-right" && <ArrowRight />}
    </S.Button>
  )
}
