import { rem } from "polished"
import styled from "styled-components"
import {
  AddToBag,
  ArrowRight,
  ArrowLeft,
  shakeTheBag,
  Configure,
} from "../elements"

export const ButtonContent = styled.span``

export const Button = styled.button`
  transition: all 0.2s ease-in-out;
  width: auto;
  color: ${(p) => p.theme.colors.purple};
  position: relative;
  cursor: pointer;
  background: none;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  padding: 0;
  height: ${rem(54)};
  border: none;

  ${AddToBag},
  ${ArrowRight}, ${Configure} {
    transition: transform 0.25s ease-in-out;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  ${Configure} {
    height: 1.5rem;
    width: 1.5rem;
    fill: transparent;
    stroke: currentColor;
  }

  ${ArrowLeft} {
    transition: transform 0.25s ease-in-out;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  ${AddToBag}, ${ArrowLeft}, ${Configure} {
    margin-right: 0.5rem;
  }

  ${ArrowRight} {
    margin-left: 0.5rem;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  span {
    position: relative;

    &:after {
      transition: width 0.2s ease-in-out;
      height: 1px;
      background-color: currentColor;
      width: 0;
      content: "";
      display: block;
      position: absolute;
      bottom: -4px;
    }
  }

  &:hover {
    ${ArrowRight} {
      transform: translateX(0.25rem);
    }
    ${ArrowLeft} {
      transform: translateX(-0.25rem);
    }
    ${AddToBag} {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
  }
  &:hover,
  &:active,
  &:focus {
    span::after {
      width: 100%;
      opacity: 1;
    }
  }
`

export const Link = Button.withComponent("a")
