import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react"
import { IconType } from "../Button"
import * as S from "./elements"
import { AddToBag, ArrowRight, ArrowLeft, Configure } from "../elements"

type BaseButtonProps = {
  icon?: IconType
}

type Button = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: "button"
}
type Link = AnchorHTMLAttributes<HTMLAnchorElement> & {
  as?: "link"
  disabled?: boolean
}

type ButtonAs = Link | Button

export type ButtonProps = BaseButtonProps & ButtonAs

const LinkAnchor: React.FC<ButtonProps> = ({
  icon,
  children,
  onClick,
  as = "button",
  ...rest
}) => {
  const Comp: any = as === "link" ? S.Link : S.Button
  return (
    <Comp onClick={onClick} {...rest}>
      {icon === "add-to-bag" && <AddToBag />}
      {icon === "configure" && <Configure />}
      {icon === "arrow-left" && <ArrowLeft />}
      <S.ButtonContent>{children}</S.ButtonContent>
      {icon === "arrow-right" && <ArrowRight />}
    </Comp>
  )
}

export default LinkAnchor
