import Link, { LinkProps } from "next/link"
import { useRouter } from "next/router"
import React, { ReactElement } from "react"

export type IntlLinkProps = Omit<LinkProps, "children">

interface Props extends IntlLinkProps {
  children: ReactElement
}

const IntlLink: React.FC<Props> = ({ href, as, children, ...rest }) => {
  const router = useRouter()
  const language = router?.query.lang || "nl"
  const localizedHref =
    typeof href === "string"
      ? `/[lang]${href}`
      : { ...href, pathname: `/[lang]${href.pathname}` }

  const localizedAs =
    typeof as === "string"
      ? `/${language}${as}`
      : { ...as, pathname: `/${language}${as?.pathname || ""}` }

  return (
    <Link href={localizedHref} as={localizedAs} {...rest}>
      {React.cloneElement(children, {
        href: localizedAs,
      })}
    </Link>
  )
}

export default IntlLink
