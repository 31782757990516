import React from "react" //eslint-disable-line
import * as Icons from "@components/Icons"
import { rem, rgba } from "polished"
import styled, { keyframes, css } from "styled-components"
import { IconType } from "./Button"
import LoaderIcon from "./loader.svg"
import { maxWidth } from "styled-system"

export const shakeTheBag = keyframes`
0% {
  transform: rotate(0);
}
25% {
  transform: rotate(-15deg);
}
50% {
  transform: rotate(0);
}
75% {
  transform: rotate(15deg);
}
100% {
  transform: rotate(0);
}
`

export const ArrowRight = styled(Icons.ArrowRight)``
export const ArrowLeft = styled(Icons.ArrowLeft)``
export const AddToBag = styled(Icons.AddToBag)``
export const Configure = styled(Icons.Configure)``

export const Loader = styled(LoaderIcon)``

interface StyledButtonProps {
  icon?: IconType
}

// @ts-ignore
export const baseButton = css`
  ${maxWidth};
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  background: none;
  width: 100%;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  height: ${rem(54)};

  ${AddToBag},
  ${ArrowRight}, ${Configure} {
    transition: transform 0.25s ease-in-out;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  ${ArrowLeft} {
    transition: transform 0.25s ease-in-out;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  ${Configure} {
    height: 1.5rem;
    width: 1.5rem;
    fill: transparent;
    stroke: currentColor;
  }

  ${AddToBag}, ${ArrowLeft}, ${Configure} {
    margin-right: 1rem;
  }

  ${ArrowRight} {
    margin-left: 1rem;
  }

  ${Loader} {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    stroke: currentColor;
  }
`

export const WhiteBorderButton = styled.button<StyledButtonProps>`
  ${baseButton};
  border: 1.5px solid #fff;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    border-color: ${(p) => p.theme.colors.softGrey};
    color: ${(p) => p.theme.colors.softGrey};
    ${AddToBag} {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
    ${ArrowRight} {
      transform: translateX(0.25rem);
    }
    ${ArrowLeft} {
      transform: translateX(-0.25rem);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    fill: white;
  }
`

export const WhiteBorderButtonAnchor = WhiteBorderButton.withComponent("a")

export const PurpleBorderButton = styled.button<StyledButtonProps>`
  ${baseButton};
  border: 1.5px solid ${(p) => p.theme.colors.purple};
  color: ${(p) => p.theme.colors.purple};
  &:hover,
  &:active,
  &:focus {
    border-color: ${(p) => p.theme.colors.purpleLight};
    background: ${(p) => rgba(p.theme.colors.purpleLight, 0.03)};

    ${AddToBag} {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
    ${ArrowRight} {
      transform: translateX(0.25rem);
    }
    ${ArrowLeft} {
      transform: translateX(-0.25rem);
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    fill: ${(p) => p.theme.colors.purple};
  }
`
export const PurpleBorderButtonAnchor = PurpleBorderButton.withComponent("a")

export const DarkBorderButton = styled.button<StyledButtonProps>`
  ${baseButton};
  border: 1px solid ${(p) => p.theme.colors.text.dark};
  color: ${(p) => p.theme.colors.text.dark};
  &:hover,
  &:active,
  &:focus {
    border-color: ${(p) => p.theme.colors.text.dark};
    background: ${(p) => rgba(p.theme.colors.text.dark, 0.03)};

    ${AddToBag} {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
    ${ArrowRight} {
      transform: translateX(0.25rem);
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    fill: ${(p) => p.theme.colors.text.dark};
  }
`

export const DarkBorderButtonAnchor = DarkBorderButton.withComponent("a")

export const PurpleButton = styled.button<StyledButtonProps>`
  ${baseButton};
  font-weight: bold;
  background: ${(p) => p.theme.colors.purple};
  color: #fff;
  border: none;
  &:hover,
  &:active,
  &:focus {
    background: ${(p) => p.theme.colors.purpleLight};
    ${AddToBag} {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
    ${ArrowRight} {
      transform: translateX(0.25rem);
    }
    ${ArrowLeft} {
      transform: translateX(-0.25rem);
    }
  }

  &:disabled {
    background: ${(p) => rgba(p.theme.colors.purple, 0.8)};
    &:hover,
    &:focus,
    &:active {
      cursor: not-allowed;
    }
  }

  svg {
    fill: white;
  }
`

export const PurpleButtonAnchor = PurpleButton.withComponent("a")
